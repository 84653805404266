import * as React from 'react';
import { useState, FormEvent } from 'react';

import Storage from '@aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';

import {
    useRecordContext,
    useCreate,
    // useUpdate,
    useRefresh,
    useNotify,
    useGetIdentity,
    // Identifier,
    useResourceContext,
} from 'react-admin';
import { TextField as TextInput, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { StatusSelector } from './StatusSelector';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
    },
    small: {
        marginRight: '1em',
        '& .MuiFilledInput-input': {
            paddingTop: 10,
        },
    },
    uploadButton: {
        marginRight: '5px',
    },
}));

export const NewNote = ({
    userEmail,
    showStatus,
    reference,
}: {
    userEmail: string;
    showStatus?: boolean;
    reference: 'contacts' | 'projects';
}) => {
    const classes = useStyles();
    const record = useRecordContext();
    const resource = useResourceContext();
    const [attachments, setAttachments] = useState<any[]>([]);
    const [text, setText] = useState('');
    const [status, setStatus] = useState(record && record.status);
    const [date, setDate] = useState(getCurrentDate());
    const [create, { loading }] = useCreate();
    // const [update] = useUpdate();
    // FIXME: use refetch instead when ReferenceManyField exposes it in the ListContext
    const refresh = useRefresh();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    if (!record || !identity) return null;

    const handleFileInputChange = (event: any) => {
        setAttachments(event.target.files);
    };

    const uploadToS3 = async (file: any) => {
        if (file) {
            try {
                const fileName = uuidv4() + '-' + file.name;

                const s3Response = await Storage.put(fileName, file, {
                    contentType: file.type,
                    level: 'public',
                });

                return s3Response.key;
            } catch (e) {
                console.log('error in uploading file to s3:' + e);
                return null;
            }
        }

        return null;
    };

    const createNotesAttachment = async (
        id: number,
        s3Key: string,
        fileName: string,
        type: string
    ) => {
        const resource = showStatus
            ? 'ContactNotesAttachments'
            : 'ProjectNotesAttachments';
        const common = {
            s3Key,
            fileName,
            type,
        };

        const param = showStatus
            ? {
                  ...common,
                  contactNotesId: id,
              }
            : {
                  ...common,
                  projectNotesId: id,
              };

        const response = await create(resource, param);

        return response;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data: any = {
            [foreignKeyMapping[reference]]: record.id,
            user: userEmail,
            date: new Date(date),
            text,
        };
        if (showStatus) {
            data.status = status;
        }

        create(resource, data, {
            onSuccess: (createResponse: any) => {
                const keys = Object.keys(attachments);
                const promises = [];
                if (
                    createResponse &&
                    createResponse.data &&
                    keys &&
                    keys.length > 0
                ) {
                    const { id } = createResponse.data;

                    for (let i = 0; i < keys.length; i++) {
                        const key: any = keys[i];
                        const attachment = attachments[key];
                        const promise = uploadToS3(attachment).then(
                            async (s3Key: any) => {
                                await createNotesAttachment(
                                    id,
                                    s3Key,
                                    attachment.name,
                                    attachment.type
                                );
                            }
                        );
                        promises.push(promise);
                    }
                }

                if (promises.length > 0) {
                    Promise.all(promises).then(() => {
                        setText('');
                        notify('Note added successfully');
                        refresh();
                    });
                } else {
                    setText('');
                    notify('Note added successfully');
                    refresh();
                }
            },
        });
        return false;
    };
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <TextInput
                    label="Add a note"
                    variant="filled"
                    size="small"
                    fullWidth
                    multiline
                    value={text}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setText(event.target.value)
                    }
                    rows={3}
                />
                <div className={classes.toolbar}>
                    <span>
                        {text ? (
                            <>
                                {showStatus && (
                                    <StatusSelector
                                        status={status}
                                        setStatus={setStatus}
                                        className={classes.small}
                                    />
                                )}
                                <TextInput
                                    type="datetime-local"
                                    variant="filled"
                                    size="small"
                                    value={date}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        setDate(event.target.value);
                                    }}
                                    className={classes.small}
                                />
                            </>
                        ) : null}
                    </span>
                    <div>
                        <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            className={classes.uploadButton}
                        >
                            {attachments && attachments.length} Upload File
                            <input
                                type="file"
                                hidden
                                multiple
                                name="attachments"
                                onChange={handleFileInputChange}
                            />
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!text || loading}
                        >
                            Add this note
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const getCurrentDate = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, -1);
};

const foreignKeyMapping = {
    contacts: 'contactId',
    projects: 'projectId',
};
