import React, { useState, useEffect } from 'react';

import {
    Tooltip,
    Paper,
    Button,
    Card,
    List,
    ListItem,
    Typography,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

import { parse } from 'papaparse';
import { parse as dateParse } from 'date-fns';

import { useCreate } from 'react-admin';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        marginTop: '25px',
        padding: '15px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    icon: {},
    card: {
        marginTop: '12px',
        padding: '15px',
        width: '100%',
    },
    clear: {
        marginLeft: '4px',
    },
}));

export const CreateInvoices = () => {
    const classes = useStyles();

    const [invoicesFile, setInvoicesFile] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [create, { loading, error, data, loaded }] = useCreate();
    const [results, setResults] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);

    const handleFileInputChange = (event: any) => {
        if (
            event &&
            event.target &&
            event.target.files &&
            event.target.files.length > 0
        ) {
            const csvFiles = event.target.files;
            const csvFile = csvFiles[0];

            // check if file type is csv
            if (csvFile.type !== 'text/csv') {
                // show error
            } else {
                setInvoicesFile(csvFiles[0]);
            }
        }
    };

    useEffect(() => {
        const insertInvoiceRow = async (invoiceRow: any) => {
            await create('invoices', invoiceRow);
        };

        const createInvoiceRow = (row: any) => {
            try {
                const postedDate = dateParse(row[0], 'dd-MMM-yy', new Date());

                return {
                    companyName: row[6],
                    postedDate: postedDate.toISOString(),
                    projectId: null,
                    projectName: row[1],
                };
            } catch (e) {
                console.log(e);
                return null;
            }
        };

        const insertInvoices = async (invoices: any[]) => {
            const copy = [...invoices];

            // remove first item
            copy.shift();
            copy.forEach(async invoice => {
                const row = createInvoiceRow(invoice);
                await insertInvoiceRow(row);
            });
        };
        if (invoicesFile) {
            setIsLoading(true);
            parse(invoicesFile, {
                complete: async data => {
                    await insertInvoices(data.data);
                    setIsLoading(false);
                },
                error: () => {
                    setIsLoading(false);
                },
            });
        }
    }, [invoicesFile, create]);

    useEffect(() => {
        if (error) {
            const errorsCopy = [...errors];
            errorsCopy.push(error.errors[0].message);
            setErrors(errorsCopy);
        }

        // Commenting this out to hide the result for the succesful inserts
        // if (data) {
        //     const resultsCopy = [...results];
        //     resultsCopy.push(data);
        //     setResults(resultsCopy);
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, loaded, error]);

    const reset = () => {
        setInvoicesFile(undefined);
        setErrors([]);
        setResults([]);
    };

    return (
        <Paper elevation={0} className={classes.container}>
            <div>
                <Tooltip title="Upload Invoices">
                    <Button
                        variant="contained"
                        component="label"
                        color="secondary"
                        disabled={isLoading}
                    >
                        Upload File
                        <input
                            type="file"
                            hidden
                            name="attachments"
                            onChange={handleFileInputChange}
                            accept=".csv"
                        />
                    </Button>
                </Tooltip>
                <Tooltip title="Reset">
                    <Button
                        variant="contained"
                        component="label"
                        color="secondary"
                        disabled={isLoading}
                        onClick={reset}
                        className={classes.clear}
                    >
                        Clear
                    </Button>
                </Tooltip>
            </div>
            {results && results.length > 0 && (
                <Card className={classes.card}>
                    <Typography variant="h6">Successful Updates</Typography>
                    <List>
                        {results.map(result => (
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Successfully updated {result.id}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Card>
            )}
            {errors && errors.length > 0 && (
                <Card className={classes.card}>
                    <Typography variant="h6">Errors</Typography>
                    <List>
                        {errors.map(error => (
                            <ListItem>
                                <ListItemIcon>
                                    <ErrorIcon />
                                </ListItemIcon>
                                <ListItemText>{error}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Card>
            )}
        </Paper>
    );
};
