/* eslint-disable import/no-anonymous-default-export */
import React, { useContext } from 'react';
import { TextInput, SelectInput } from 'react-admin';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AmplifyFilter } from 'react-admin-amplify';

import UserEmailContext from '../UserEmailProvider';

const defaultQuery = 'listContacts';

const useStyles = makeStyles(theme => ({
    filterInput: {
        width: '15em',
    },
}));

export const ContactListFilter = (props: any) => {
    const classes = useStyles();
    const { loading, userEmail } = useContext(UserEmailContext);

    if (loading) {
        return <div></div>;
    }

    return (
        <Box width="15em" order="-1" marginRight="1em">
            <AmplifyFilter {...props} defaultQuery={defaultQuery}>
                <TextInput
                    source="contactsBySearch.search"
                    label="Search"
                    alwaysOn
                    ressetable
                    className={classes.filterInput}
                />
                <SelectInput
                    choices={[
                        {
                            id: userEmail,
                            name: userEmail,
                        },
                    ]}
                    source="contactsBySalesOfficerEmail.email"
                    label="SalesOfficer"
                    alwaysOn
                    ressetable
                    className={classes.filterInput}
                />
            </AmplifyFilter>
        </Box>
    );
};
