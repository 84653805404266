import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Toolbar, AppBar, Box, Typography } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Link, useRouteMatch } from 'react-router-dom';
import {
    UserMenu,
    Logout,
    LoadingIndicator,
    usePermissions,
    MenuItemLink,
} from 'react-admin';
import { useUserAdminStatus } from './misc/useUserAdminStatus';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    logo: {
        width: 50,
        height: 43.54,
    },
});

const Header = () => {
    const classes = useStyles();
    const match = useRouteMatch([
        '/contacts',
        '/companies',
        '/projects',
        '/users',
        '/invoices',
    ]);
    const currentPath = match?.path ?? '/';

    const { loaded, permissions } = usePermissions();
    const { isAdmin } = useUserAdminStatus();

    if (!loaded) return null;

    return (
        <nav className={classes.root}>
            <AppBar position="static" color="primary">
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            {/* <img
                                className={classes.logo}
                                src={
                                    'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'
                                }
                                alt="Bosch Logo"
                            /> */}
                            <Typography component="span" variant="h5">
                                Teravera SAM
                            </Typography>
                        </Box>
                        <Box>
                            <Tabs
                                value={currentPath}
                                aria-label="Navigation Tabs"
                            >
                                {/* <Tab
                                    label={'Dashboard'}
                                    component={Link}
                                    to="/"
                                    value="/"
                                /> */}
                                <Tab
                                    label={'Contacts'}
                                    component={Link}
                                    to="/contacts"
                                    value="/contacts"
                                />
                                <Tab
                                    label={'Companies'}
                                    component={Link}
                                    to="/companies"
                                    value="/companies"
                                />
                                <Tab
                                    label={'Deals'}
                                    component={Link}
                                    to="/projects"
                                    value="/projects"
                                />
                                {isAdmin && (
                                    <Tab
                                        label={'Users'}
                                        component={Link}
                                        to="/users"
                                        value="/users"
                                    />
                                )}
                                {(isAdmin ||
                                    permissions.indexOf('AccountingOfficer') !==
                                        -1) && (
                                    <Tab
                                        label={'Invoices'}
                                        component={Link}
                                        to="/invoices"
                                        value="/invoices"
                                    />
                                )}
                            </Tabs>
                        </Box>
                        <Box display="flex">
                            <LoadingIndicator />
                            <UserMenu
                                logout={<Logout button />}
                                label={
                                    permissions && permissions[0]
                                        ? permissions[0]
                                        : 'profile'
                                }
                            >
                                <MenuItemLink
                                    leftIcon={<RotateLeftIcon />}
                                    primaryText="Change password"
                                    to="/change-password"
                                />
                            </UserMenu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </nav>
    );
};

export default Header;
