import React, { useContext } from 'react';

import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    useRefresh,
} from 'react-admin';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import UserEmailContext from '../UserEmailProvider';

import { stageChoices } from './stages';
// import { typeChoices } from './types';
import { Project } from '../types';

const useStyles = makeStyles({
    root: {
        width: 500,
    },
});

export const DealCreate = ({ open }: { open: boolean }) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { loading, userEmail } = useContext(UserEmailContext);

    const handleClose = () => {
        redirect('/projects');
    };

    const onSuccess = ({ data: deal }: { data: Project }) => {
        refresh();
        redirect('/projects');
        // increase the index of all deals in the same stage as the new deal
        // dataProvider
        //     .getList('deals', {
        //         pagination: { page: 1, perPage: 50 },
        //         sort: { field: 'id', order: 'ASC' },
        //         filter: { stage: deal.stage },
        //     })
        //     .then(({ data: deals }) =>
        //         Promise.all(
        //             deals
        //                 .filter(oldDeal => oldDeal.id !== deal.id)
        //                 .map(oldDeal =>
        //                     dataProvider.update('deals', {
        //                         id: oldDeal.id,
        //                         data: { index: oldDeal.index + 1 },
        //                         previousData: oldDeal,
        //                     })
        //                 )
        //         )
        //     );
    };

    const transform = (data: any) => {
        return { ...data, salesOfficerEmail: userEmail };
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Create
                resource="projects"
                basePath="/projects"
                className={classes.root}
                onSuccess={onSuccess}
                transform={transform}
            >
                <SimpleForm>
                    <TextInput
                        source="name"
                        label="Deal name"
                        fullWidth
                        validate={[required()]}
                    />
                    <ReferenceInput
                        source="companyId"
                        label="Company"
                        reference="companies"
                        fullWidth
                        validate={[required()]}
                    >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <SelectInput
                        source="stage"
                        choices={stageChoices}
                        fullWidth
                        validate={[required()]}
                        defaultValue="opportunity"
                    />
                    {/* <SelectInput
                        source="type"
                        choices={typeChoices}
                        fullWidth
                    /> */}
                    {/* <NumberInput source="amount" fullWidth defaultValue={0} /> */}
                </SimpleForm>
            </Create>
        </Dialog>
    );
};
