import React from 'react';
import PhilippinePeso from './philippines-peso-currency-symbol.png';

const PhilippinePesoIcon = () => {
    const imageStyle = {
        width: '16px',
        height: '16px',
        opacity: 0.3,
        marginRight: '6px',
    };
    return <img src={PhilippinePeso} style={imageStyle} alt="PHP" />;
};

export default PhilippinePesoIcon;
