import React, { useEffect, useState } from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { IconButton, Tooltip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Storage from '@aws-amplify/storage';

const useStyles = makeStyles(theme => ({
    fileName: {
        textDecoration: 'none',
    },
}));

export const NotesAttachment = (props: any) => {
    const { attachment } = props;
    const styles = useStyles();

    const [downloadLink, setDownloadLink] = useState<string>('');

    useEffect(() => {
        const getSignedS3Link = async (s3Key: string) => {
            const link = await Storage.get(s3Key);
            setDownloadLink(link);
        };

        getSignedS3Link(attachment.s3Key);
    }, [attachment]);

    return (
        <div>
            <a
                target="_blank"
                rel="noreferrer"
                href={downloadLink}
                className={styles.fileName}
            >
                <Tooltip title={attachment.fileName}>
                    <>
                        <IconButton aria-label={attachment.fileName}>
                            <AttachmentIcon />
                        </IconButton>
                        <span>{attachment.fileName}</span>
                    </>
                </Tooltip>
            </a>
        </div>
    );
};
