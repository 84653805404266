import * as React from 'react';
import { useState, ChangeEvent } from 'react';
import {
    ShowBase,
    ShowProps,
    TextField,
    ReferenceManyField,
    SelectField,
    useShowContext,
    useRecordContext,
    useListContext,
} from 'react-admin';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Tabs,
    Tab,
    Divider,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from '../contacts/Avatar';
import { Status } from '../misc/Status';
import { TagsList } from '../contacts/TagsList';
import { sizes } from './sizes';
import { LogoField } from './LogoField';
import { CompanyAside } from './CompanyAside';
import { Company, Contact, Project } from '../types';
import { stageNames } from '../deals/stages';
import { CompanyAvatar } from './CompanyAvatar';
import { statuses } from './statuses';

export const CompanyShow = (props: ShowProps) => (
    <ShowBase {...props}>
        <CompanyShowContent />
    </ShowBase>
);

const CompanyShowContent = () => {
    const { record, loaded } = useShowContext<Company>();
    const logo = record && record.logo ? record.logo : '';
    const [value, setValue] = useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    if (!loaded || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box display="flex" mb={1}>
                            {record.logo ? (
                                <LogoField
                                    record={{
                                        logo: logo || '',
                                        name: record.name,
                                    }}
                                />
                            ) : (
                                <CompanyAvatar record={record} />
                            )}
                            <Box ml={2} flex="1">
                                <Typography variant="h5">
                                    {record.name}
                                </Typography>
                                <Typography variant="body2">
                                    <TextField source="sector.name" />,{' '}
                                    <SelectField
                                        source="size"
                                        choices={sizes}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            {record.contacts && (
                                <Tab
                                    label={
                                        record.contacts.length === 1
                                            ? '1 Contact'
                                            : `${record.contacts.length} Contacts`
                                    }
                                />
                            )}
                            {record.projects && (
                                <Tab
                                    label={
                                        record.projects.length === 1
                                            ? '1 deal'
                                            : `${record.projects.length} Deals`
                                    }
                                />
                            )}
                        </Tabs>
                        <Divider />
                        <TabPanel value={value} index={0}>
                            {/* <ReferenceManyField
                                reference="contacts"
                                target="company_id"
                                sort={{ field: 'last_name', order: 'ASC' }}
                            >
                                <ContactsIterator />
                            </ReferenceManyField> */}
                            <ContactsIterator contacts={record.contacts} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <DealsIterator projects={record.projects} />
                        </TabPanel>
                    </CardContent>
                </Card>
            </Box>
            <CompanyAside record={record} />
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};

const ContactsIterator = ({ contacts }: { contacts: Contact[] }) => {
    if (!contacts) {
        return null;
    }
    return (
        <Box>
            <List>
                {contacts.map(contact => {
                    return (
                        <ListItem
                            button
                            key={contact.id}
                            component={RouterLink}
                            to={`/contacts/${contact.id}/show`}
                        >
                            <ListItemAvatar>
                                <Avatar record={contact} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${contact.firstName} ${contact.lastName}`}
                                secondary={
                                    <>
                                        {contact.title}{' '}
                                        {/* <TagsList record={contact} /> */}
                                    </>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
            {/* <Box textAlign="center" mt={1}>
                <CreateRelatedContactButton record={record} />
            </Box> */}
        </Box>
    );
};

const CreateRelatedContactButton = ({ record }: any) => (
    <Button
        component={RouterLink}
        to={{
            pathname: '/contacts/create',
            state: { record: { company_id: record.id } },
        }}
        color="primary"
        variant="contained"
        size="small"
        startIcon={<PersonAddIcon />}
    >
        Add contact
    </Button>
);

const DealsIterator = ({ projects }: { projects: Project[] }) => {
    if (!projects) {
        return null;
    }
    return (
        <Box>
            <List>
                {projects.map(project => {
                    return (
                        <ListItem
                            button
                            key={project.id}
                            component={RouterLink}
                            to={`/projects/${project.id}/show`}
                        >
                            <ListItemText
                                primary={project.name}
                                secondary={
                                    <SelectField
                                        source="status"
                                        choices={statuses}
                                    />
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};
