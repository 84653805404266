import { Button, useMutation } from 'react-admin';
import { User } from '../types';

export const EnableButton = (props: any) => {
    const user = props.record as User;

    const [disableUser, { loading }] = useMutation({
        type: 'update',
        resource: 'users',
        payload: { id: user.id, data: { id: user.id, operation: 'enable' } },
    });

    return <Button label="Enable" disabled={loading} onClick={disableUser} />;
};
