import { useState, useEffect } from 'react';
import { usePermissions } from 'ra-core';

export const useUserAdminStatus = () => {
    const { permissions } = usePermissions();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (permissions && permissions.length > 0) {
            if (
                permissions.indexOf('Admin') !== -1 ||
                permissions.indexOf('SuperAdmin') !== -1
            ) {
                setIsAdmin(true);
            }
        }
    }, [permissions]);

    return { isAdmin };
};
