/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'ap-southeast-1',
    aws_cognito_identity_pool_id:
        'ap-southeast-1:c9820d5d-d0e7-48b9-8771-9e839b67f924',
    aws_cognito_region: 'ap-southeast-1',
    aws_user_pools_id: 'ap-southeast-1_kDUxdx7kg',
    aws_user_pools_web_client_id: '6ogi3glq77vr29lnp3pmshk13o',
    oauth: {},
    aws_appsync_graphqlEndpoint:
        'https://ekdgb3qgvzcjzntgmeo2yhmeo4.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    Storage: {
        region: 'ap-southeast-1',
        bucket: 'sam-api-production-attachmentsbucket-fnjy3x8qvk82',
        identityPoolId: 'ap-southeast-1:c9820d5d-d0e7-48b9-8771-9e839b67f924',
    },
};

export default awsmobile;
