import { Chip } from '@material-ui/core';
import {
    Edit,
    ListProps,
    TextField,
    Show,
    SimpleForm,
    TopToolbar,
    SaveButton,
    Toolbar,
    EditButton,
    ListButton,
    FunctionField,
    BooleanField,
    SelectInput,
    usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import omit from 'lodash/omit';
import { DisableButton } from './DisableButton';
import { EnableButton } from './EnableButton';

const useStyles = makeStyles({
    inline: {
        display: 'inline-block',
        marginLeft: '1em',
        '&.first-child': {
            marginLeft: 0,
        },
    },
    toolbar: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
    },
});

// Hard coding roles for simplicity
const roleChoices = (permissions: string[]) => {
    const baseRoles = [
        { id: 'SalesOfficer', name: 'Sales Officer' },
        {
            id: 'AccountingOfficer',
            name: 'Accounting Officer',
        },
        { id: 'Admin', name: 'Admin' },
    ];

    const isSuperAdmin = permissions.includes('SuperAdmin');
    return isSuperAdmin
        ? [...baseRoles, { id: 'SuperAdmin', name: 'Super Admin' }]
        : baseRoles;
};

const UserEditActions = () => {
    return (
        <TopToolbar>
            <ListButton basePath="/users" />
        </TopToolbar>
    );
};

const UserEditToolbar = (props: any) => {
    const { record } = props;
    const omittedProps = {
        ...omit(props, [
            // FIXME Not super user firendly way to remove warnings
            'dirtyFields',
            'dirtyFieldsSinceLastSubmit',
            'dirtySinceLastSubmit',
            'hasSubmitErrors',
            'hasValidationErrors',
            'initialValues',
            'modifiedSinceLastSubmit',
            'submitError',
            'submitErrors',
            'submitFailed',
            'submitSucceeded',
            'submitting',
            'valid',
        ]),
    };
    const classes = useStyles();
    return (
        <Toolbar {...omittedProps} className={classes.toolbar}>
            <SaveButton disabled={props.pristine} />
            {record.enabled ? (
                <DisableButton {...omittedProps} />
            ) : (
                <EnableButton {...omittedProps} />
            )}
        </Toolbar>
    );
};

export const UserEdit = (props: ListProps) => {
    const { loaded, permissions } = usePermissions();
    if (!loaded) {
        return <></>;
    }
    return (
        <Edit
            mutationMode={'pessimistic'}
            {...props}
            transform={(data: any) => {
                return {
                    id: data.id,
                    operation: 'update',
                    roles: [data.roles],
                };
            }}
            actions={<UserEditActions />}
        >
            <SimpleForm toolbar={<UserEditToolbar props={props} />}>
                <TextField source="id" />
                <BooleanField source="enabled" />
                <TextField source="status" />
                <TextField source="email" />
                <SelectInput
                    source="roles"
                    choices={roleChoices(permissions)}
                />
            </SimpleForm>
        </Edit>
    );
};
