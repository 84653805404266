import { Dialog, DialogContent } from '@material-ui/core';
import {
    usePermissions,
    Identifier,
    useRedirect,
    useEditContext,
    EditBase,
    FormWithRedirect,
    TextInput,
    required,
    Toolbar,
    SaveButton,
    SelectInput,
    useGetList,
} from 'react-admin';

const toolbar = (formProps: any) => {
    return (
        <Toolbar {...formProps}>
            <SaveButton />
        </Toolbar>
    );
};

const salesOfficerChoices = (ids: any, users: any) => {
    // prettier-ignore
    return ids
        // We did not restrict the type of user to SalesOfficers only when creating a company and default project. (ie) If 
        // an admin creates a company, the new default project's SalesOfficer is the admin.
        // See CompanyCreate.tsx Line: 73
        // Show all users instead of only SalesOfficers.
        // Uncomment the next line to show SalesOfficers only.
        // .filter((id: any) => users[id].roles.includes('SalesOfficer'))
        .map((id: any) => {
            return { id: users[id].email, name: users[id].email, roles: users[id].roles };
        });
};

export const DealEdit = ({ open, id }: { open: boolean; id: Identifier }) => {
    const redirect = useRedirect();

    const handleClose = () => {
        redirect('/projects');
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <EditBase
                    resource="projects"
                    basePath="/projects"
                    id={id}
                    transform={(data: any) => {
                        return {
                            id: data.id,
                            name: data.name,
                            salesOfficerEmail: data.salesOfficerEmail,
                        };
                    }}
                >
                    <EditProjectContent />
                </EditBase>
            </DialogContent>
        </Dialog>
    );
};

const EditProjectContent = () => {
    const { record, loaded, save } = useEditContext();
    const { ids: userIds, data: usersData } = useGetList('users');
    const { loaded: permissionsLoaded, permissions } = usePermissions();

    if (!loaded || !permissionsLoaded) return null;

    return (
        <FormWithRedirect
            mutationMode="pessimistic"
            record={record}
            redirect="list"
            save={save}
            toolbar={toolbar}
            render={formProps => (
                <>
                    <TextInput
                        validate={[required()]}
                        source="name"
                        fullWidth
                    />
                    {
                        // prettier-ignore
                        (permissions.includes('SuperAdmin') || permissions.includes('Admin')) && (
                            <SelectInput
                                label="Sales Officer"
                                validate={[required()]}
                                source="salesOfficerEmail"
                                fullWidth
                                choices={salesOfficerChoices(
                                    userIds,
                                    usersData
                                )}
                                optionText={(choice: any) =>
                                    `${choice.name} (${choice.roles})`
                                }
                            />
                        )
                    }
                    {toolbar(formProps)}
                </>
            )}
        />
    );
};
