/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompanie = /* GraphQL */ `
    mutation CreateCompany($input: CreateCompanyInput!) {
        createCompany(input: $input) {
            id
        }
    }
`;
export const updateCompanie = /* GraphQL */ `
    mutation UpdateCompany($input: UpdateCompanyInput!) {
        updateCompanie(input: $input) {
            id
            name
        }
    }
`;
export const deleteCompanie = /* GraphQL */ `
    mutation DeleteCompany($input: DeleteCompanyInput!) {
        deleteCompanie(input: $input) {
            id
            name
        }
    }
`;
export const createProject = /* GraphQL */ `
    mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            id
            name
            status
            stage
            company {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateProject = /* GraphQL */ `
    mutation UpdateProject($input: UpdateProjectInput!) {
        updateProject(input: $input) {
            id
            name
            status
            stage
            salesOfficerEmail
            company {
                id
                name
                logo
            }
        }
    }
`;
export const createSalesOfficer = /* GraphQL */ `
    mutation CreateSalesOfficer($salesOfficer: CreateSalesOfficerInput!) {
        createSalesOfficer(salesOfficer: $salesOfficer) {
            id
            name
            createdAt
            updatedAt
        }
    }
`;
export const createContact = /* GraphQL */ `
    mutation CreateContact($input: CreateContactPersonInput!) {
        createContact(input: $input) {
            id
            firstName
            lastName
            title
            email
            workPhone
            homePhone
            extraPhone1
            extraPhone2
            extraPhone3
            project {
                id
                name
            }
            company {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateContact = /* GraphQL */ `
    mutation UpdateContact($input: UpdateContactPersonInput!) {
        updateContact(input: $input) {
            id
            firstName
            lastName
            title
            email
            workPhone
            homePhone
            extraPhone1
            extraPhone2
            extraPhone3
            project {
                id
                name
            }
            company {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteContact = /* GraphQL */ `
    mutation DeleteContact($input: DeleteContactPersonInput!) {
        deleteContact(input: $input) {
            id
        }
    }
`;
export const createProjectNote = /* GraphQL */ `
    mutation CreateProjectNote($input: CreateProjectNoteInput!) {
        createProjectNote(input: $input) {
            id
            type
            text
            user
            date
            createdAt
            updatedAt
        }
    }
`;
export const updateProjectNote = /* GraphQL */ `
    mutation UpdateProjectNote($input: UpdateProjectNoteInput!) {
        updateProjectNote(input: $input) {
            id
            type
            text
            user
            date
            createdAt
            updatedAt
        }
    }
`;
export const deleteProjectNote = /* GraphQL */ `
    mutation DeleteProjectNote($input: DeleteProjectNoteInput!) {
        deleteProjectNote(input: $input) {
            id
        }
    }
`;
export const createContactNote = /* GraphQL */ `
    mutation CreateContactNote($input: CreateContactNoteInput!) {
        createContactNote(input: $input) {
            id
            type
            text
            user
            status
            date
            createdAt
            updatedAt
        }
    }
`;
export const updateContactNote = /* GraphQL */ `
    mutation UpdateContactNote($input: UpdateContactNoteInput!) {
        updateContactNote(input: $input) {
            id
            type
            text
            user
            date
            status
            createdAt
            updatedAt
        }
    }
`;
export const deleteContactNote = /* GraphQL */ `
    mutation DeleteContactNote($input: DeleteContactNoteInput!) {
        deleteContactNote(input: $input) {
            id
        }
    }
`;

/** Contact Notes Attachments */
export const createContactNotesAttachment = /* GraphQL */ `
    mutation CreateContactNotesAttachment(
        $input: CreateContactNotesAttachmentInput!
    ) {
        createContactNotesAttachment(input: $input) {
            id
            s3Key
        }
    }
`;
/** Contact Notes Attachments */

/** Project Notes Attachments */
export const createProjectNotesAttachment = /* GraphQL */ `
    mutation CreateProjectNotesAttachment(
        $input: CreateProjectNotesAttachmentInput!
    ) {
        createProjectNotesAttachment(input: $input) {
            id
            s3Key
        }
    }
`;
/** Project Notes Attachments */

/** Users */
export const createUser = /* GraphQL */ `
    mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
            id
            email
            roles
            status
            enabled
        }
    }
`;
export const updateUser = /* GraphQL */ `
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            id
            email
            roles
            status
            enabled
        }
    }
`;
/** Users */

/** Invoices */
export const createInvoice = /* GraphQL */ `
    mutation CreateInvoice($input: CreateInvoiceInput!) {
        createInvoice(input: $input) {
            id
        }
    }
`;
/** Invoices */
