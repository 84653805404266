import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { NotesAttachment } from './NotesAttachment';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

export const NotesAttachmentsList = (props: any) => {
    const { attachments } = props;

    const styles = useStyles();

    return (
        <div className={styles.list}>
            {attachments.map((attachment: any) => (
                <NotesAttachment attachment={attachment} />
            ))}
        </div>
    );
};
