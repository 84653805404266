import React, { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser, defaultTheme } from 'react-admin';
import {
    unstable_createMuiStrictModeTheme,
    createMuiTheme,
} from '@material-ui/core/styles';

//import { dataProvider } from './dataProvider';
// import { authProvider } from './authProvider';
import Layout from './Layout';
import contacts from './contacts';
import companies from './companies';
import deals from './deals';
import users from './users';
import invoices from './invoices';
import { Dashboard } from './dashboard/Dashboard';

import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { buildAuthProvider, buildDataProvider } from 'react-admin-amplify';

import awsExports from './aws-exports';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import { UserEmailProvider } from './UserEmailProvider';
import { Login } from './login/Login';
import { NewPassword } from './login/NewPassword';
import { ChangePassword } from './login/ChangePassword';
import { RouteWithoutLayout } from 'react-admin';
import { Route } from 'react-router-dom';

Amplify.configure(awsExports);

const awsAmplifyDataProvider = buildDataProvider(
    { queries, mutations },
    {
        storageBucket: 'sam-api-production-attachmentsbucket-fnjy3x8qvk82',
        storageRegion: 'ap-southeast-1',
    }
);
const awsAmplifyAuthProvider = buildAuthProvider({ authGroups: [] });

// FIXME MUI bug https://github.com/mui-org/material-ui/issues/13394
const theme =
    process.env.NODE_ENV !== 'production'
        ? unstable_createMuiStrictModeTheme(defaultTheme)
        : createMuiTheme(defaultTheme);

const App = () => {
    const [userEmail, setUserEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        Auth.currentUserInfo()
            .then(data => {
                const userEmail =
                    data && data.attributes && data.attributes.email
                        ? data.attributes.email
                        : '';
                setUserEmail(userEmail);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <UserEmailProvider value={{ loading, userEmail }}>
            <Admin
                dataProvider={awsAmplifyDataProvider}
                authProvider={awsAmplifyAuthProvider}
                layout={Layout}
                dashboard={Dashboard}
                theme={theme}
                loginPage={Login}
                customRoutes={[
                    // This route is not accessible publicly due to awsAmplifyAuthProvider not returning an empty array of permissions (throws rejection instead)
                    // The current side effect is when a user has logged in using a temporary password, he is redirected back again to the login page (react-admin thinks its an invalid login)
                    // https://github.com/MrHertal/react-admin-amplify/blob/db8fc5f55911470475759d792d7d2474cb5dcff8/src/providers/AuthProvider.ts#L68
                    // https://stackoverflow.com/a/67311858
                    // The current workaround is to use the temporary password as a permanent password which should then be changed by the user after loggin in
                    <RouteWithoutLayout
                        exact
                        path="/new-password"
                        component={NewPassword}
                        noLayout
                    />,
                    <Route
                        exact
                        path="/change-password"
                        component={ChangePassword}
                    />,
                ]}
            >
                {permissions => [
                    <Resource name="projects" {...deals} />,
                    <Resource name="sectors" />,
                    <Resource name="pcabLicenses" />,
                    <Resource name="contactNotesAttachments" />,
                    <Resource name="projectNotesAttachments" />,
                    <Resource name="contacts" {...contacts} />,
                    <Resource name="companies" {...companies} />,
                    <Resource name="contactNotes" />,
                    <Resource name="projectNotes" />,
                    permissions.includes('SuperAdmin') ||
                    permissions.includes('Admin') ? (
                        <Resource name="users" {...users} />
                    ) : null,
                    <Resource name="invoices" {...invoices} />,
                ]}
            </Admin>
        </UserEmailProvider>
    );
};

export default App;
