/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import {
    List as RaList,
    ListProps,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    usePermissions,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Avatar } from './Avatar';
import { Status } from '../misc/Status';
import { TagsList } from './TagsList';
import { ContactListFilter } from './ContactListFilter';
import { Contact } from '../types';

import { useUserAdminStatus } from '../misc/useUserAdminStatus';

const ContactListContent = () => {
    const { data, ids, loaded, onToggleItem, selectedIds } = useListContext<
        Contact
    >();
    const now = Date.now();
    if (!loaded) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    const unique = ids.reduce((a: any[], c: any) => {
        if (a.indexOf(c) === -1) {
            a.push(c);
            return a;
        }

        return a;
    }, []);

    return (
        <List>
            {unique.map(id => {
                const contact = data[id];
                return (
                    <ListItem
                        button
                        key={id}
                        component={Link}
                        to={`/contacts/${id}/show`}
                    >
                        {/* <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={selectedIds.includes(id)}
                                tabIndex={-1}
                                disableRipple
                                onClick={e => {
                                    e.stopPropagation();
                                    onToggleItem(id);
                                }}
                            />
                        </ListItemIcon> */}
                        <ListItemAvatar>
                            <Avatar record={contact} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${contact.firstName} ${contact.lastName}`}
                            secondary={
                                <>
                                    {contact.title ? contact.title : `Employee`}
                                    {contact.company && (
                                        <>
                                            {` at `}
                                            <ReferenceField
                                                record={contact.company}
                                                source="id"
                                                reference="companies"
                                                basePath="/companies"
                                                link={false}
                                            >
                                                <>
                                                    <TextField source="name" />
                                                </>
                                            </ReferenceField>{' '}
                                            {/* {contact.nb_notes &&
                                              `- ${contact.nb_notes} notes `}
                                          <TagsList record={contact} /> */}
                                        </>
                                    )}
                                </>
                            }
                        />
                        {/* <ListItemSecondaryAction>
                            <Typography variant="body2" color="textSecondary">
                                last activity{' '}
                                {formatDistance(
                                    new Date(contact.last_seen),
                                    now
                                )}{' '}
                                ago <Status status={contact.status} />
                            </Typography>
                        </ListItemSecondaryAction> */}
                    </ListItem>
                );
            })}
        </List>
    );
};

const useActionStyles = makeStyles(theme => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
}));
const ContactListActions = () => {
    const classes = useActionStyles();
    const { isAdmin } = useUserAdminStatus();
    return (
        <TopToolbar>
            {/* <SortButton fields={['last_name', 'first_name', 'last_seen']} /> */}
            {isAdmin && <ExportButton />}
            <CreateButton
                basePath="/contacts"
                variant="contained"
                label="New Contact"
                className={classes.createButton}
            />
        </TopToolbar>
    );
};

export const ContactList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            {...props}
            actions={<ContactListActions />}
            aside={<ContactListFilter />}
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[]} />}
            // filterDefaultValues={{ sales_id: identity?.id }}
            sort={{ field: 'lastName', order: 'DESC' }}
        >
            <ContactListContent />
        </RaList>
    ) : null;
};
