import React from 'react';
import {
    Edit,
    EditProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
    SelectInput,
    ImageInput,
    ImageField,
    Toolbar,
    SaveButton,
    DeleteButton,
    useRecordContext,
    required,
    usePermissions,
} from 'react-admin';
import { Box, CardContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Storage from '@aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';

import { CompanyAside } from './CompanyAside';
import { LogoField } from './LogoField';
// import { sectors } from './sectors';
import { sizes } from './sizes';

const useStyles = makeStyles({
    inline: {
        display: 'inline-block',
        marginLeft: '1em',
        '&.first-child': {
            marginLeft: 0,
        },
    },
    toolbar: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
    },
});

const CompanyEditToolbar = (props: any) => {
    const { permissions } = props;
    const classes = useStyles();

    if (
        (permissions && permissions.indexOf('Admin') !== -1) ||
        permissions.indexOf('SuperAdmin') !== -1
    ) {
        return (
            <Toolbar {...props} className={classes.toolbar}>
                <SaveButton />
                <DeleteButton />
            </Toolbar>
        );
    }
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

export const CompanyEdit = (props: EditProps) => {
    const classes = useStyles();
    const { loading, permissions } = usePermissions();

    if (loading) {
        return <div></div>;
    }
    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            aside={<CompanyAside link="show" />}
            actions={false}
            transform={async (data: any) => {
                const sectorId = data.sector.id;
                const pcabLicenseId = data.pcabLicense.id;
                let s3Response = null;
                delete data.sector;
                delete data.pcabLicense;

                if (data.projects) {
                    delete data.projects;
                }
                if (data.contacts) {
                    delete data.contacts;
                }

                if (data.logo && data.logo.rawFile) {
                    const { logo } = data;
                    const fileName = uuidv4() + '-' + logo.title;

                    s3Response = await Storage.put(fileName, logo.rawFile, {
                        contentType: logo.rawFile.type,
                        level: 'public',
                    });
                }

                return {
                    ...data,
                    sectorId,
                    pcabLicenseId,
                    logo: s3Response?.key,
                };
            }}
        >
            <SimpleForm
                component={CustomLayout}
                redirect="show"
                toolbar={<CompanyEditToolbar permissions={permissions} />}
            >
                <TextInput source="name" validate={required()} fullWidth />
                <ReferenceInput
                    source="sector.id"
                    reference="sectors"
                    validate={required()}
                    label="Sector"
                    formClassName={clsx(classes.inline, 'first-child')}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(sector: any) => `${sector.name}`}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="pcabLicense.id"
                    reference="pcabLicenses"
                    validate={required()}
                    label="PCAB Licnese"
                    formClassName={classes.inline}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(pcabLicense: any) => `${pcabLicense.name}`}
                    />
                </ReferenceInput>
                <SelectInput
                    source="size"
                    choices={sizes}
                    formClassName={classes.inline}
                />
                <CustomDivider />
                <TextInput source="address" fullWidth helperText={false} />
                <TextInput
                    source="city"
                    formClassName={clsx(classes.inline, 'first-child')}
                />

                <TextInput source="province" formClassName={classes.inline} />
                <CustomDivider />

                <ImageInput source="logo" label="Company Logo" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>

                <CustomDivider />
                <TextInput
                    source="phoneNumber"
                    formClassName={clsx(classes.inline, 'first-child')}
                    helperText={false}
                />
                {/* <ReferenceInput
                    source="sales_id"
                    reference="sales"
                    label="Account manager"
                    formClassName={classes.inline}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(sales: any) =>
                            `${sales.first_name} ${sales.last_name}`
                        }
                    />
                </ReferenceInput> */}
            </SimpleForm>
        </Edit>
    );
};

const CustomLayout = (props: any) => {
    const record = useRecordContext(props);
    return (
        <CardContent>
            <Box display="flex">
                <LogoField record={record as any} />
                <Box ml={2} flex="1" maxWidth={796}>
                    {props.children}
                </Box>
            </Box>
        </CardContent>
    );
};

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);
