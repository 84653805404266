import React, { useContext } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    ListProps,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
} from 'react-admin';
import { Route, Switch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { AmplifyFilter } from 'react-admin-amplify';

import UserEmailContext from '../UserEmailProvider';

import { DealListContent } from './DealListContent';
import { DealCreate } from './DealCreate';
import { DealShow } from './DealShow';
// import { OnlyMineInput } from './OnlyMineInput';
// import { typeChoices } from './types';

import { useUserAdminStatus } from '../misc/useUserAdminStatus';
import { DealEdit } from './DealEdit';

const useActionStyles = makeStyles(theme => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
    filterInput: {
        width: '15em',
    },
}));

export const DealList = (props: ListProps) => {
    const { identity } = useGetIdentity();
    const classes = useActionStyles();
    const { loading, userEmail } = useContext(UserEmailContext);

    const dealFilters = (
        <AmplifyFilter defaultQuery="listProjects">
            <SelectInput
                choices={[
                    {
                        id: userEmail,
                        name: userEmail,
                    },
                ]}
                source="projectsBySalesOfficerEmail.email"
                label="Sales Officer"
                alwaysOn
                ressetable
                className={classes.filterInput}
            />
        </AmplifyFilter>
    );

    if (loading) {
        return <div></div>;
    }

    return identity ? (
        <>
            <List
                {...props}
                perPage={100}
                sort={{ field: 'index', order: 'ASC' }}
                filters={dealFilters}
                // filterDefaultValues={{ sales_id: identity && identity?.id }}
                actions={<DealActions />}
                pagination={false}
                component="div"
            >
                <DealListContent />
            </List>
            <Switch>
                <Route exact path="/projects/create">
                    {({ match }) => <DealCreate open={!!match} />}
                </Route>
                <Route exact path="/projects/:id/show">
                    {({ match }) =>
                        !!match ? (
                            <DealShow open={!!match} id={match?.params?.id} />
                        ) : null
                    }
                </Route>
                <Route exact path="/projects/:id">
                    {({ match }) =>
                        !!match ? (
                            <DealEdit open={!!match} id={match?.params?.id} />
                        ) : null
                    }
                </Route>
            </Switch>
        </>
    ) : null;
};

const DealActions = () => {
    const classes = useActionStyles();
    const { isAdmin } = useUserAdminStatus();
    return (
        <TopToolbar>
            {/* <FilterButton /> */}
            {isAdmin && <ExportButton />}
            <CreateButton
                basePath="/projects"
                variant="contained"
                label="New Deal"
                className={classes.createButton}
            />
        </TopToolbar>
    );
};
