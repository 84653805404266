import * as React from 'react';
import { EmailField, DateField, EditButton, ShowButton } from 'react-admin';
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    phoneLink: {
        marginRight: '3px',
    },
    phoneSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '3px !important',
        marginBottom: '3px !important',
    },
});

export const ContactAside = ({ record, link = 'edit' }: any) => {
    const styles = useStyles();

    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton
                        basePath="/contacts"
                        record={record}
                        label="Edit Contact"
                    />
                ) : (
                    <ShowButton
                        basePath="/contacts"
                        record={record}
                        label="Show Contact"
                    />
                )}
            </Box>

            <Typography variant="subtitle2">Personal info</Typography>
            <Divider />

            <Box mt={2}>
                <EmailField source="email" />
            </Box>

            <Box mt={1} className={styles.phoneSection}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Work
                </Typography>
                <a
                    href={`tel:${record.workPhone}`}
                    className={styles.phoneLink}
                >
                    {record.workPhone}
                </a>
            </Box>

            <Box mb={1} className={styles.phoneSection}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Home
                </Typography>
                <a
                    href={`tel:${record.homePhone}`}
                    className={styles.phoneLink}
                >
                    {record.homePhone}
                </a>
            </Box>

            <Box mb={1} className={styles.phoneSection}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Extra Phone #1
                </Typography>
                <a
                    href={`tel:${record.extraPhone1}`}
                    className={styles.phoneLink}
                >
                    {record.extraPhone1}
                </a>
            </Box>

            <Box mb={1} className={styles.phoneSection}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Extra Phone #2
                </Typography>
                <a
                    href={`tel:${record.extraPhone2}`}
                    className={styles.phoneLink}
                >
                    {record.extraPhone2}
                </a>
            </Box>

            <Box mb={1} className={styles.phoneSection}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Extra Phone #3
                </Typography>
                <a
                    href={`tel:${record.extraPhone3}`}
                    className={styles.phoneLink}
                >
                    {record.extraPhone3}
                </a>
            </Box>

            <Typography variant="subtitle2">Background</Typography>
            <Divider />

            <Box mt={2}>{record && record.background}</Box>
            <Box mt={1} mb={3}>
                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Updated on
                </Typography>{' '}
                <DateField
                    source="updatedAt"
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    color="textSecondary"
                />
                <br />
                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Added on
                </Typography>{' '}
                <DateField
                    source="createdAt"
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    color="textSecondary"
                />
                <br />
                {/* <Typography component="span" variant="body2" color="textSecondary">
                Last seen on
            </Typography>{' '}
            <DateField
                source="last_seen"
                options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                color="textSecondary"
            />
            <br /> */}
                {/* <Typography component="span" variant="body2" color="textSecondary">
                Followed by
            </Typography>{' '}
            <ReferenceField
                resource="contacts"
                source="sales_id"
                reference="sales"
            >
                <FunctionField<Sale>
                    source="last_name"
                    render={record =>
                        record ? `${record.first_name} ${record.last_name}` : ''
                    }
                />
            </ReferenceField> */}
            </Box>

            {/* <Box mb={3}>
            <Typography variant="subtitle2">Tags</Typography>
            <Divider />
            <TagsListEdit record={record} />
        </Box> */}

            {/* <ReferenceManyField
            resource="contacts"
            target="contact_id"
            reference="tasks"
        >
            <TasksIterator />
        </ReferenceManyField> */}
        </Box>
    );
};

// TODO: Notes
// const TasksIterator = () => {
//     const { data, ids, loading } = useListContext();
//     if (loading || ids.length === 0) return null;
//     return (
//         <Box>
//             <Typography variant="subtitle2">Tasks</Typography>
//             <Divider />

//             <List>
//                 {ids.map(id => {
//                     const task = data[id];
//                     return (
//                         <ListItem key={id} disableGutters>
//                             <Box>
//                                 <Typography variant="body2">
//                                     {task.text}
//                                 </Typography>
//                                 <Typography
//                                     variant="body2"
//                                     color="textSecondary"
//                                 >
//                                     due{' '}
//                                     <DateField
//                                         source="due_date"
//                                         record={task}
//                                     />
//                                 </Typography>
//                             </Box>
//                         </ListItem>
//                     );
//                 })}
//             </List>
//         </Box>
//     );
// };
