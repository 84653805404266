import React, { useState, useEffect } from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useRecordContext,
    useRedirect,
    Identifier,
    EditButton,
} from 'react-admin';
import {
    Box,
    Dialog,
    DialogContent,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NotesIterator } from '../notes';
import { ContactList } from './ContactList';
import { stageNames } from './stages';
import { LogoField } from '../companies/LogoField';
import { useContext } from 'react';
import UserEmailContext from '../UserEmailProvider';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        top: 50,
    },
});

export const DealShow = ({ open, id }: { open: boolean; id: Identifier }) => {
    const redirect = useRedirect();
    const classes = useStyles();

    const handleClose = () => {
        redirect('/projects');
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            classes={{ paper: classes.dialog }}
        >
            <DialogContent>
                <ShowBase resource="projects" basePath="/projects" id={id}>
                    <DealShowContent />
                </ShowBase>
            </DialogContent>
        </Dialog>
    );
};

const DealShowContent = () => {
    const record = useRecordContext();
    const { loading, userEmail } = useContext(UserEmailContext);

    const [currentRecord, setCurrentRecord] = useState<any>();

    useEffect(() => {
        if (record) {
            setCurrentRecord(record);
        }
    }, [record]);

    if (!userEmail || loading || !currentRecord) {
        return <></>;
    }

    return (
        <>
            <Box display="flex">
                <Box
                    width={100}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <LogoField
                        record={{
                            logo: currentRecord.company.logo,
                            name: currentRecord.company.name,
                        }}
                    />
                    <ReferenceField
                        source="company.id"
                        reference="companies"
                        link="show"
                    >
                        <TextField
                            source="name"
                            align="center"
                            component="div"
                        />
                    </ReferenceField>
                </Box>
                <Box ml={2} flex="1">
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h5">
                            {currentRecord.name}
                        </Typography>
                        <EditButton
                            basePath="/projects"
                            record={currentRecord}
                        />
                    </Box>
                    <Box display="flex" mt={2}>
                        {/* <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Start
                            </Typography>
                            <Typography variant="subtitle1">
                                {format(new Date(record.start_at), 'PP')}
                            </Typography>
                        </Box> */}

                        {/* <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Budget
                            </Typography>
                            <Typography variant="subtitle1">
                                {record.amount.toLocaleString('en-US', {
                                    notation: 'compact',
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'narrowSymbol',
                                    minimumSignificantDigits: 3,
                                })}
                            </Typography>
                        </Box> */}

                        {/* <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Type
                            </Typography>
                            <Typography variant="subtitle1">
                                {record.type}
                            </Typography>
                        </Box> */}

                        <Box display="flex" mr={5} flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                Stage
                            </Typography>
                            <Typography variant="subtitle1">
                                {/* @ts-ignore */}
                                {stageNames[currentRecord.stage]}
                            </Typography>
                        </Box>
                    </Box>

                    <Box mt={2} mb={2}>
                        <Box
                            display="flex"
                            mr={5}
                            flexDirection="column"
                            minHeight={48}
                        >
                            <Typography color="textSecondary" variant="body2">
                                Contacts
                            </Typography>

                            {currentRecord.contacts?.length > 0 ? (
                                <ContactList data={currentRecord.contacts} />
                            ) : (
                                <Typography variant="subtitle1">
                                    None
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    <Box mt={2} mb={2}>
                        <Typography color="textSecondary" variant="body2">
                            Sales Officer
                        </Typography>
                        <Typography variant="subtitle1">
                            {currentRecord.salesOfficerEmail}
                        </Typography>
                    </Box>

                    <Divider />

                    <Box mt={2}>
                        <ReferenceManyField
                            target="projectId"
                            reference="projectNotes"
                            // sort={{ field: 'date', order: 'DESC' }}
                        >
                            <NotesIterator
                                reference="projects"
                                userEmail={userEmail}
                            />
                        </ReferenceManyField>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
