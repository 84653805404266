import * as React from 'react';

import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'inline-block',
    },
    li: {
        display: 'inline',
        '&:after': {
            content: '", "',
        },
        '&:last-child:after': {
            content: '""',
        },
    },
});

export const ContactList = (props: any) => {
    const { data } = props;

    const classes = useStyles();

    return (
        <ul className={classes.ul}>
            {data.map((contact: any) => (
                <li key={contact.id} className={classes.li}>
                    <Link
                        component={RouterLink}
                        to={`/contacts/${contact.id}`}
                        variant="subtitle1"
                    >
                        {contact.firstName} {contact.lastName}
                    </Link>
                </li>
            ))}
        </ul>
    );
};
