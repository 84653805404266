import {
    List,
    ListProps,
    Datagrid,
    TextField,
    FunctionField,
    TopToolbar,
    CreateButton,
} from 'react-admin';
import { useListContext } from 'ra-core';
import { BooleanField, SimpleListLoading } from 'ra-ui-materialui';
import { User } from '../types';
import { Chip } from '@material-ui/core';

const UserListContent = () => {
    const { loaded } = useListContext<User>();

    if (!loaded) {
        return <SimpleListLoading />;
    }

    return (
        <Datagrid rowClick="edit">
            <TextField source="email" />
            <BooleanField source="enabled" />
            <TextField source="status" />
            <FunctionField
                label="Roles"
                render={(record: any) => {
                    return record.roles.map((role: any) => {
                        return <Chip label={role} />;
                    });
                }}
            />
        </Datagrid>
    );
};

const UserListActions = () => {
    return (
        <TopToolbar>
            <CreateButton label="Add User" basePath="/users" />
        </TopToolbar>
    );
};

export const UserList = (props: ListProps) => {
    return (
        <List
            bulkActionButtons={false}
            actions={<UserListActions />}
            pagination={false}
            {...props}
        >
            <UserListContent />
        </List>
    );
};
