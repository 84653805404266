/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useContext } from 'react';
import { TextInput, SelectInput, useGetList } from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { sizes } from './sizes';

import { AmplifyFilter } from 'react-admin-amplify';

import UserEmailContext from '../UserEmailProvider';

const defaultQuery = 'listCompanies';

export interface SelectOption {
    id: number;
    name: string;
}
const initialNumberOfEmployeesOptions: SelectOption[] = [...sizes];

const useStyles = makeStyles(theme => ({
    filterInput: {
        width: '15em',
    },
}));

export const CompanyListFilter = (props: any) => {
    const classes = useStyles();
    const { data } = useGetList('sectors');
    const { loading, userEmail } = useContext(UserEmailContext);
    const [sectorChoices, setSectorChoices] = useState<SelectOption[]>();

    const [numberOfEmployeesChoices] = useState<SelectOption[]>(
        initialNumberOfEmployeesOptions
    );

    useEffect(() => {
        if (data) {
            const options: any[] = [...Object.values(data)];
            setSectorChoices(options);
        }
    }, [data]);

    if (loading) {
        return <div></div>;
    }

    return (
        <Box width="15em" order="-1" marginRight="1em">
            <AmplifyFilter {...props} defaultQuery={defaultQuery}>
                <TextInput
                    source="companiesByName.name"
                    label="Search"
                    alwaysOn
                    ressetable
                    className={classes.filterInput}
                />
                <SelectInput
                    choices={[
                        { id: 'hot', name: 'hot' },
                        { id: 'cold', name: 'cold' },
                    ]}
                    source="companiesByStatus.status"
                    label="Status"
                    alwaysOn
                    className={classes.filterInput}
                />
                <SelectInput
                    choices={sectorChoices}
                    source="companiesBySector.sector"
                    label="Sector"
                    alwaysOn
                    className={classes.filterInput}
                />
                <SelectInput
                    choices={numberOfEmployeesChoices}
                    source="companiesByEmployees.employees"
                    label="No. of Employees"
                    alwaysOn
                    className={classes.filterInput}
                />

                <SelectInput
                    choices={[
                        {
                            id: userEmail,
                            name: userEmail,
                        },
                    ]}
                    source="companiesBySalesOfficerEmail.email"
                    label="SalesOfficer"
                    alwaysOn
                    ressetable
                    className={classes.filterInput}
                />
            </AmplifyFilter>
        </Box>
    );
};
