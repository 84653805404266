import * as React from 'react';
import { useEffect } from 'react';

import { useHistory } from 'react-router';

export const Dashboard = () => {
    const history = useHistory();
    useEffect(() => {
        history.push('/projects');
    }, [history]);

    return <div>Loading...</div>;
};
