import React, { useEffect, useState } from 'react';
import {
    ShowBase,
    ShowProps,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useShowContext,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import { Avatar } from './Avatar';
import { ContactAside } from './ContactAside';
import { LogoField } from '../companies/LogoField';
import { NotesIterator } from '../notes';
import { Contact } from '../types';
import UserEmailContext from '../UserEmailProvider';
import { useContext } from 'react';

export const ContactShow = (props: ShowProps) => (
    <ShowBase {...props}>
        <ContactShowContent />
    </ShowBase>
);

const ContactShowContent = () => {
    const { record, loaded } = useShowContext<Contact>();
    const { loading, userEmail } = useContext(UserEmailContext);

    const [currentRecord, setCurrentRecord] = useState<Contact>();

    useEffect(() => {
        if (record) {
            setCurrentRecord(record);
        }
    }, [record]);

    if (!loaded || !record || !userEmail || loading || !currentRecord)
        return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Avatar
                                record={currentRecord ? currentRecord : record}
                            />
                            <Box ml={2} flex="1">
                                <Typography variant="h5">
                                    {currentRecord?.firstName}{' '}
                                    {currentRecord?.lastName}
                                </Typography>
                                <Typography variant="body2">
                                    {currentRecord?.title
                                        ? currentRecord?.title
                                        : `Employee`}
                                    {currentRecord?.company && (
                                        <>
                                            {` at `}
                                            <ReferenceField
                                                source="company.id"
                                                reference="companies"
                                                link="show"
                                            >
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </>
                                    )}
                                </Typography>
                            </Box>
                            <Box>
                                <ReferenceField
                                    source="company.id"
                                    reference="companies"
                                    link="show"
                                >
                                    <LogoField />
                                </ReferenceField>
                            </Box>
                        </Box>
                        <ReferenceManyField
                            target="contactId"
                            reference="contactNotes"
                            // sort={{ field: 'date', order: 'DESC' }}
                        >
                            <NotesIterator
                                showStatus
                                reference="contacts"
                                userEmail={userEmail}
                            />
                        </ReferenceManyField>
                    </CardContent>
                </Card>
            </Box>
            <ContactAside record={currentRecord} />
        </Box>
    );
};
