import { Button, useMutation } from 'react-admin';
import { User } from '../types';

export const DisableButton = (props: any) => {
    const user = props.record as User;

    const [disableUser, { loading }] = useMutation({
        type: 'update',
        resource: 'users',
        payload: { id: user.id, data: { id: user.id, operation: 'disable' } },
    });

    return (
        <Button
            label="Disable"
            color="default"
            disabled={loading}
            onClick={disableUser}
        />
    );
};
