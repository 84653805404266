import React, {
    HtmlHTMLAttributes,
    ComponentType,
    ReactNode,
    useRef,
    useEffect,
} from 'react';
import classnames from 'classnames';
import { Card, Avatar, Theme } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
    useCheckAuth,
    TitleComponent,
    defaultTheme,
    Notification,
} from 'react-admin';
import LoginForm from './LoginForm';

export interface LoginProps
    extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
    backgroundImage?: string;
    children?: ReactNode;
    classes?: object;
    className?: string;
    notification?: ComponentType;
    staticContext?: StaticContext;
    theme?: object;
    title?: TitleComponent;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage:
                'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            // @ts-ignore
            backgroundColor: theme.palette.secondary[500],
        },
    }),
    { name: 'RaLogin' }
);

export const Login: React.FunctionComponent = props => {
    const containerRef = useRef<HTMLDivElement>();
    const classes = useStyles(props);

    const checkAuth = useCheckAuth();
    const history = useHistory();

    useEffect(() => {
        checkAuth({}, false)
            .then((data: any) => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page or redirect to new password page
            });
    }, [checkAuth, history]);

    return (
        <ThemeProvider theme={createMuiTheme(defaultTheme)}>
            <div
                className={classnames(classes.main)}
                // @ts-ignore
                ref={containerRef}
            >
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <LoginForm />
                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};
