import React from 'react';

const UserEmailContext = React.createContext({
    loading: false,
    userEmail: '',
});

export const UserEmailProvider = UserEmailContext.Provider;

export default UserEmailContext;
