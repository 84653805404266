import React, { useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
    Create,
    CreateProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
    SelectInput,
    ImageInput,
    ImageField,
    required,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';
import { Box, CardContent, Divider, Avatar } from '@material-ui/core';

import Storage from '@aws-amplify/storage';

import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import clsx from 'clsx';

import UserEmailContext from '../UserEmailProvider';

import { sizes } from './sizes';

const useStyles = makeStyles({
    inline: {
        display: 'inline-block',
        marginLeft: '1em',
        '&.first-child': {
            marginLeft: 0,
        },
    },
});

export const CompanyCreate = (props: CreateProps) => {
    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { loading, userEmail } = useContext(UserEmailContext);

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/companies');
        refresh();
    };

    // #TODO remove this once error can be resolved
    const onFailure = (error: any) => {
        // notify(`Could not edit post: ${error.message}`);
        console.log('error: ', error);
        redirect('/companies');
        refresh();
    };

    const transform = async (data: any) => {
        // submit the image to s3
        const { logo } = data;
        const fileName = uuidv4() + '-' + logo.title;

        const s3Response = await Storage.put(fileName, logo.rawFile, {
            contentType: logo.rawFile.type,
            level: 'public',
        });

        return { ...data, logo: s3Response.key, salesOfficerEmail: userEmail };
    };

    if (!userEmail || loading) {
        return <div></div>;
    }

    return (
        <Create
            {...props}
            onSuccess={onSuccess}
            onFailure={onFailure}
            transform={transform}
        >
            <SimpleForm component={CustomLayout} redirect="show">
                <TextInput source="name" validate={required()} fullWidth />
                <ReferenceInput
                    source="sectorId"
                    reference="sectors"
                    validate={required()}
                    label="Sector"
                    formClassName={clsx(classes.inline, 'first-child')}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(sector: any) => `${sector.name}`}
                    />
                </ReferenceInput>
                <ReferenceInput
                    source="pcabLicenseId"
                    reference="pcabLicenses"
                    validate={required()}
                    label="PCAB License"
                    formClassName={classes.inline}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(pcabLicense: any) => `${pcabLicense.name}`}
                    />
                </ReferenceInput>
                <SelectInput
                    source="size"
                    choices={sizes}
                    formClassName={classes.inline}
                    defaultValue={1}
                />
                <CustomDivider />
                <TextInput source="address" fullWidth helperText={false} />
                <TextInput
                    source="city"
                    formClassName={clsx(classes.inline, 'first-child')}
                />

                <TextInput source="province" formClassName={classes.inline} />
                <CustomDivider />

                {/* <TextInput source="logo" fullWidth /> */}
                <ImageInput source="logo" label="Company Logo" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <CustomDivider />
                <TextInput
                    source="phoneNumber"
                    formClassName={clsx(classes.inline, 'first-child')}
                    helperText={false}
                />
                {/* <ReferenceInput
                    source="sales_id"
                    reference="sales"
                    label="Account manager"
                    formClassName={classes.inline}
                    helperText={false}
                >
                    <SelectInput
                        optionText={(sales: any) =>
                            `${sales.first_name} ${sales.last_name}`
                        }
                    />
                </ReferenceInput> */}
            </SimpleForm>
        </Create>
    );
};

const CustomLayout = (props: any) => (
    <CardContent>
        <Box display="flex">
            <Box paddingTop={1}>
                <Avatar>
                    <BusinessIcon />
                </Avatar>
            </Box>
            <Box ml={2} flex="1" maxWidth={796}>
                {props.children}
            </Box>
        </Box>
    </CardContent>
);

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);
