import {
    CreateProps,
    Create,
    SimpleForm,
    TextInput,
    required,
    email,
    SelectInput,
    usePermissions,
    TopToolbar,
    ListButton,
} from 'react-admin';

// Hard coding roles for simplicity
const roleChoices = (permissions: string[]) => {
    const baseRoles = [
        { id: 'SalesOfficer', name: 'Sales Officer' },
        {
            id: 'AccountingOfficer',
            name: 'Accounting Officer',
        },
        { id: 'Admin', name: 'Admin' },
    ];

    // SuperAdmin can also create another super admin
    const isSuperAdmin = permissions.includes('SuperAdmin');
    return isSuperAdmin
        ? [...baseRoles, { id: 'SuperAdmin', name: 'Super Admin' }]
        : baseRoles;
};

const UserCreateActions = () => {
    return (
        <TopToolbar>
            <ListButton basePath="/users" />
        </TopToolbar>
    );
};

export const UserCreate = (props: CreateProps) => {
    const { loaded, permissions } = usePermissions();
    if (!loaded) {
        return <></>;
    }
    return (
        <Create
            {...props}
            //@ts-ignore
            transform={(data: any) => {
                return {
                    email: data.email,
                    roles: [data.roles],
                };
            }}
            actions={<UserCreateActions />}
        >
            <SimpleForm>
                <TextInput
                    source="email"
                    type="email"
                    validate={[required(), email()]}
                />
                {/* Note that we have support for multiple roles in the backend. In that case, SelectArrayInput can be used*/}
                <SelectInput
                    label="Role"
                    source="roles"
                    validate={[required()]}
                    choices={roleChoices(permissions)}
                />
            </SimpleForm>
        </Create>
    );
};
