import { Chip } from '@material-ui/core';
import {
    ListProps,
    TextField,
    Show,
    SimpleShowLayout,
    TopToolbar,
    EditButton,
    ListButton,
    FunctionField,
    BooleanField,
    useShowContext,
} from 'react-admin';
import { User } from '../types';

export const UserShow = (props: ListProps) => {
    return (
        <Show actions={<UserShowActions />} {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <BooleanField source="enabled" />
                <TextField source="status" />
                <TextField source="email" />
                <FunctionField
                    Label="Roles"
                    source="roles"
                    render={(record: any) => {
                        return record.roles.map((role: any) => {
                            return <Chip label={role} />;
                        });
                    }}
                />
            </SimpleShowLayout>
        </Show>
    );
};

const UserShowActions = () => {
    const { record } = useShowContext<User>();

    return (
        <TopToolbar>
            <ListButton basePath="/users" />
            <EditButton basePath="/users" record={record} />
        </TopToolbar>
    );
};
