import * as React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';

import { Contact } from '../types';

export const Avatar = ({ record }: { record: Contact }) => (
    <MuiAvatar src={record.avatar}>
        {record.firstName.charAt(0)}
        {record.lastName.charAt(0)}
    </MuiAvatar>
);
