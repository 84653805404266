import * as React from 'react';
import {
    EditBase,
    EditProps,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    FormWithRedirect,
    Toolbar,
    useEditContext,
    usePermissions,
    SaveButton,
} from 'react-admin';
import { Card, CardContent, Divider, Box } from '@material-ui/core';
import omit from 'lodash/omit';

import { Project } from '../API';

import { Avatar } from './Avatar';
import { ContactAside } from './ContactAside';
import { Contact } from '../types';

const Spacer = () => <Box width={20} component="span" />;

const ContactEditContent = () => {
    const { record, loaded, save } = useEditContext<Contact>();
    const { loading: loadingPermissions, permissions } = usePermissions();

    if (!loaded || !record || loadingPermissions) return null;

    const toolbar = (formProps: any) => {
        const omittedProps = {
            ...omit(formProps, [
                // FIXME Not super user firendly way to remove warnings
                'dirtyFields',
                'dirtyFieldsSinceLastSubmit',
                'dirtySinceLastSubmit',
                'hasSubmitErrors',
                'hasValidationErrors',
                'initialValues',
                'modifiedSinceLastSubmit',
                'submitError',
                'submitErrors',
                'submitFailed',
                'submitSucceeded',
                'submitting',
                'valid',
            ]),
        };

        if (
            permissions.includes('Admin') ||
            permissions.includes('SuperAdmin')
        ) {
            return <Toolbar {...omittedProps} />;
        } else {
            return (
                <Toolbar {...omittedProps}>
                    <SaveButton />
                </Toolbar>
            );
        }
    };
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <FormWithRedirect
                    mutationMode="pessimistic"
                    record={record}
                    redirect="show"
                    save={save}
                    render={formProps => (
                        <Card>
                            <CardContent>
                                <Box>
                                    <Box display="flex">
                                        <Box mr={2}>
                                            <Avatar record={record} />
                                        </Box>
                                        <Box flex="1" mt={-1}>
                                            <Box display="flex">
                                                <TextInput source="firstName" />
                                                <Spacer />
                                                <TextInput source="lastName" />
                                            </Box>
                                            <Box display="flex">
                                                <TextInput source="title" />
                                                <Spacer />
                                                <ReferenceInput
                                                    source="project.id"
                                                    reference="projects"
                                                    label="Project"
                                                    allowEmpty={true}
                                                >
                                                    <SelectInput
                                                        optionText={(
                                                            project: Project
                                                        ) =>
                                                            `${project.name} @ ${project.company?.name}`
                                                        }
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                            <Divider />
                                            <Box mt={2} width={430}>
                                                <TextInput
                                                    source="email"
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box display="flex">
                                                <TextInput source="workPhone" />
                                                <Spacer />
                                                <TextInput source="homePhone" />
                                                <Spacer />
                                                <TextInput source="extraPhone1" />
                                                <Spacer />
                                                <TextInput source="extraPhone2" />
                                                <Spacer />
                                                <TextInput source="extraPhone3" />
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                            {toolbar(formProps)}
                        </Card>
                    )}
                />
            </Box>
            <ContactAside record={record} link="show" />
        </Box>
    );
};

export const ContactEdit = (props: EditProps) => (
    <EditBase
        {...props}
        transform={(data: any) => {
            const projectId = data.project?.id;

            if (data.hasOwnProperty('project')) {
                delete data.project;
            }
            if (data.hasOwnProperty('company')) {
                delete data.company;
            }

            return {
                ...data,
                projectId,
            };
        }}
    >
        <ContactEditContent />
    </EditBase>
);
