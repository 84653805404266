import * as React from 'react';
import {
    CreateBase,
    CreateProps,
    TextInput,
    ReferenceInput,
    FormWithRedirect,
    Toolbar,
    required,
    useCreateContext,
    useNotify,
    useRefresh,
    useRedirect,
    useGetList,
    SelectInput,
} from 'react-admin';
import { Card, CardContent, Divider, Box, Avatar } from '@material-ui/core';

import { Project } from '../API';

const Spacer = () => <Box width={20} component="span" />;

export const ContactCreate = (props: CreateProps) => {
    return (
        <CreateBase {...props}>
            <ContactCreateContent />
        </CreateBase>
    );
};

const ContactCreateContent = () => {
    const { save, record } = useCreateContext();
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <FormWithRedirect
                    redirect="show"
                    record={record as any}
                    save={save}
                    render={formProps => (
                        <Card>
                            <CardContent>
                                <Box>
                                    <Box display="flex">
                                        <Box mr={2}>
                                            <Avatar />
                                        </Box>
                                        <Box flex="1" mt={-1}>
                                            <Box display="flex">
                                                <TextInput
                                                    source="firstName"
                                                    validate={[required()]}
                                                />
                                                <Spacer />
                                                <TextInput
                                                    source="lastName"
                                                    validate={[required()]}
                                                />
                                            </Box>
                                            <Box display="flex">
                                                <TextInput source="title" />
                                                <Spacer />
                                                <ReferenceInput
                                                    source="projectId"
                                                    reference="projects"
                                                    label="Project"
                                                    allowEmpty={true}
                                                >
                                                    <SelectInput
                                                        optionText={(
                                                            project: Project
                                                        ) =>
                                                            `${project.name} @ ${project.company?.name}`
                                                        }
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                            <Divider />
                                            <Box mt={2} width={430}>
                                                <TextInput
                                                    source="email"
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box display="flex">
                                                <TextInput source="workPhone" />
                                                <Spacer />
                                                <TextInput source="homePhone" />
                                                <Spacer />
                                                <TextInput source="extraPhone1" />
                                                <Spacer />
                                                <TextInput source="extraPhone2" />
                                                <Spacer />
                                                <TextInput source="extraPhone3" />
                                            </Box>
                                            <Divider />
                                            {/* <Box mt={2} width={430}>
                                                <TextInput
                                                    source="background"
                                                    multiline
                                                    fullWidth
                                                />
                                                <TextInput
                                                    source="avatar"
                                                    fullWidth
                                                />
                                                <BooleanInput source="has_newsletter" />
                                            </Box> */}
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                            <Toolbar {...formProps} />
                        </Card>
                    )}
                />
            </Box>
        </Box>
    );
};
