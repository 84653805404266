import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

import Storage from '@aws-amplify/storage';

const useStyles = makeStyles({
    image: {
        objectFit: 'contain',
    },
});

const sizeInPixel = {
    medium: 42,
    small: 20,
};

export const LogoField = ({
    record,
    source,
    size = 'medium',
}: {
    record?: { logo: string; name: string };
    source?: string;
    size?: 'small' | 'medium';
}) => {
    const classes = useStyles();
    const [companyLogo, setCompanyLogo] = useState<any | null>();

    useEffect(() => {
        if (record && record.logo && typeof record.logo === 'string') {
            Storage.get(record.logo)
                .then(file => {
                    setCompanyLogo(file);
                })
                .catch(err => {
                    console.log('error encountered: ', err);
                    setCompanyLogo(null);
                });
        }
    }, [record]);

    if (!record) return <div></div>;

    if (
        (!record.logo || typeof record.logo !== 'string' || !companyLogo) &&
        record.name
    ) {
        return <Avatar>{record.name.charAt(0)}</Avatar>;
    }

    return (
        <img
            src={companyLogo}
            alt={record.name}
            title={record.name}
            width={sizeInPixel[size]}
            height={sizeInPixel[size]}
            className={classes.image}
        />
    );
};
