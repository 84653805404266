import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import passwordValidator from 'password-validator';

interface FormData {
    oldPassword: string;
    password1: string;
    password2: string;
}

const passwordSchema = new passwordValidator();
passwordSchema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .has()
    .symbols(1);

const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    //@ts-ignore
    meta: { touched, error },
    //@ts-ignore
    input: inputProps,
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ChangePassword = (props: any) => {
    const classes = useStyles(props);
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();

    const validate = (values: FormData) => {
        const errors = { password1: undefined, password2: undefined };

        //@ts-ignore
        if (!values.oldPassword) {
            // @ts-ignore
            errors.oldPassword = translate('ra.validation.required');
        }
        const validations = passwordSchema.validate(values.password1, {
            details: true,
        });
        //@ts-ignore
        if (validations.length > 0) {
            //@ts-ignore
            errors.password1 = validations[0].message;
        }
        if (!values.password1) {
            // @ts-ignore
            errors.password1 = translate('ra.validation.required');
        }
        if (!values.password2) {
            // @ts-ignore
            errors.password2 = translate('ra.validation.required');
        }
        if (values.password1 !== values.password2) {
            // @ts-ignore
            errors.password2 = 'Passwords do not match';
        }
        return errors;
    };

    const submit = (values: any) => {
        setLoading(true);
        Auth.currentAuthenticatedUser().then(user => {
            return Auth.changePassword(
                user,
                values.oldPassword,
                values.password2
            )
                .then(data => {
                    console.log(data);
                    notify('Password successfully changed', 'success');
                    login({
                        username: user.username,
                        password: values.password2,
                    });
                })
                .catch(err => {
                    if (err.code === 'NotAuthorizedException') {
                        notify('Incorrect credentials', 'error');
                    } else {
                        notify(err.message, 'error');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <Field
                                autoFocus
                                id="oldPassword"
                                name="oldPassword"
                                component={Input}
                                label="Current Password"
                                type="password"
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password1"
                                name="password1"
                                component={Input}
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.input}>
                            <Field
                                id="password2"
                                name="password2"
                                component={Input}
                                label="Re-type password"
                                type="password"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            Change Password
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};
