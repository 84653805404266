/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCompanies = /* GraphQL */ `
    query ListCompanies(
        $filter: FilterCompanyInput
        $limit: Int!
        $nextToken: String
    ) {
        listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

export const listProjects = /* GraphQL */ `
    query ListProjects($limit: Int!, $nextToken: String) {
        listProjects(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                status
                company {
                    id
                    name
                    logo
                }
                createdAt
                updatedAt
                stage
                salesOfficerEmail
                index
            }
            nextToken
        }
    }
`;

export const listProjectNotes = /* GraphQL */ `
    query ListProjectNotes($limit: Int!, $nextToken: String) {
        listProjectNotes(limit: $limit, nextToken: $nextToken) {
            items {
                id
                type
                text
                createdAt
                updatedAt
                user
                date
                projectNotesAttachments {
                    id
                    s3Key
                    fileName
                    type
                }
            }
            nextToken
        }
    }
`;

export const listContactNotes = /* GraphQL */ `
    query ListContactNotes($limit: Int!, $nextToken: String) {
        listContactNotes(limit: $limit, nextToken: $nextToken) {
            items {
                id
                type
                text
                createdAt
                updatedAt
                user
                status
                date
                contactNotesAttachments {
                    id
                    s3Key
                    fileName
                    type
                }
            }
            nextToken
        }
    }
`;

export const listSectors = /* GraphQL */ `
    query ListSectors($limit: Int!, $nextToken: String) {
        listSectors(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const listPcabLicenses = /* GraphQL */ `
    query ListPcabLicenses($limit: Int!, $nextToken: String) {
        listPcabLicenses(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;

export const listProjectsByCompanyId = /* GraphQL */ `
    query ListProjectsByCompanyId(
        $companyId: Int!
        $limit: Int!
        $nextToken: String
    ) {
        listProjectsByCompanyId(
            companyId: $companyId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                status
                createdAt
                updatedAt
            }
        }
    }
`;

export const listProjectNotesByProjectId = /* GraphQL */ `
    query ListProjectNotesByProjectId(
        $projectId: Int!
        $limit: Int!
        $nextToken: String
    ) {
        listProjectNotesByProjectId(
            projectId: $projectId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                text
                createdAt
                updatedAt
                user
                date
                projectNotesAttachments {
                    id
                    s3Key
                    fileName
                    type
                }
            }
        }
    }
`;

export const listContactNotesByContactId = /* GraphQL */ `
    query ListContactNotesByContactId(
        $contactId: Int!
        $limit: Int!
        $nextToken: String
    ) {
        listContactNotesByContactId(
            contactId: $contactId
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                text
                status
                createdAt
                updatedAt
                user
                date
                contactNotesAttachments {
                    id
                    s3Key
                    fileName
                    type
                }
            }
        }
    }
`;

export const listContacts = /* GraphQL */ `
    query ListContacts($limit: Int!, $nextToken: String) {
        listContacts(limit: $limit, nextToken: $nextToken) {
            items {
                id
                firstName
                lastName
                title
                email
                workPhone
                homePhone
                company {
                    id
                    name
                }
                project {
                    id
                    name
                }
            }
            nextToken
        }
    }
`;

export const listUsers = /* GraphQL */ `
    query ListUsers {
        listUsers {
            items {
                id
                email
                roles
                status
                enabled
            }
            nextToken
        }
    }
`;

export const getCompanie = /* GraphQL */ `
    query GetCompanie($id: Int!) {
        getCompanie(id: $id) {
            id
            name
            phoneNumber
            address
            createdAt
            updatedAt
            logo
            city
            province
            email
            status
            sector {
                id
                name
            }
            pcabLicense {
                id
                name
            }
            size
            projects {
                id
                name
                status
                stage
                createdAt
                updatedAt
            }
            contacts {
                id
                firstName
                lastName
                title
            }
        }
    }
`;

export const getSector = /* GraphQL */ `
    query GetSector($id: Int!) {
        getSector(id: $id) {
            id
            name
        }
    }
`;
export const getPcabLicense = /* GraphQL */ `
    query GetPcabLicense($id: Int!) {
        getPcabLicense(id: $id) {
            id
            name
        }
    }
`;
export const getContact = /* GraphQL */ `
    query GetContact($id: Int!) {
        getContact(id: $id) {
            id
            firstName
            lastName
            title
            email
            workPhone
            homePhone
            project {
                id
                name
            }
            company {
                id
                name
            }
            createdAt
            updatedAt
            extraPhone1
            extraPhone2
            extraPhone3
        }
    }
`;

export const getProject = /* GraphQL */ `
    query GetProject($id: Int!) {
        getProject(id: $id) {
            id
            name
            status
            stage
            company {
                id
                name
                logo
            }
            createdAt
            updatedAt
            index
            contacts {
                firstName
                lastName
                id
            }
            salesOfficerEmail
        }
    }
`;

export const getAllProjects = /* GraphQL */ `
    query GetAllProjects {
        getAllProjects {
            id
            name
            status
            company {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                sector
                pcabLicense
                size
            }
            createdAt
            updatedAt
            index
        }
    }
`;
export const getUser = /* GraphQL */ `
    query GetUser($id: String!) {
        getUser(id: $id) {
            id
            email
            roles
            status
            enabled
        }
    }
`;
export const getAllSalesOfficers = /* GraphQL */ `
    query GetAllSalesOfficers {
        getAllSalesOfficers {
            id
            name
            createdAt
            updatedAt
        }
    }
`;
export const company = /* GraphQL */ `
    query Company {
        company {
            id
            name
            phoneNumber
            address
            createdAt
            updatedAt
            logo
            city
            province
            email
            status
            sector
            pcabLicense
            size
            projects {
                id
                name
                status
                createdAt
                updatedAt
            }
        }
    }
`;
export const projects = /* GraphQL */ `
    query Projects {
        projects {
            id
            name
            status
            company {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                sector
                pcabLicense
                size
            }
            createdAt
            updatedAt
            index
        }
    }
`;

/** Company Filters  */

export const companiesBySector = /* GraphQL */ `
    query CompaniesBySector(
        $sector: String!
        $limit: Int!
        $nextToken: String
    ) {
        companiesBySector(
            sector: $sector
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

export const companiesByStatus = /* GraphQL */ `
    query CompaniesByStatus(
        $status: String!
        $limit: Int!
        $nextToken: String
    ) {
        companiesByStatus(
            status: $status
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

export const companiesByEmployees = /* GraphQL */ `
    query CompaniesByEmployees(
        $employees: String!
        $limit: Int!
        $nextToken: String
    ) {
        companiesByEmployees(
            employees: $employees
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

export const companiesByName = /* GraphQL */ `
    query CompaniesByName($name: String!, $limit: Int!, $nextToken: String) {
        companiesByName(name: $name, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

export const companiesBySalesOfficerEmail = /* GraphQL */ `
    query CompaniesBySalesOfficerEmail(
        $email: String!
        $limit: Int!
        $nextToken: String
    ) {
        companiesBySalesOfficerEmail(
            email: $email
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                phoneNumber
                address
                createdAt
                updatedAt
                logo
                city
                province
                email
                status
                defaultProjectId
                sector {
                    id
                    name
                }
                size
                projects {
                    id
                    name
                    status
                }
                contacts {
                    id
                    firstName
                    lastName
                    title
                }
            }
            nextToken
        }
    }
`;

/** Company Filters  */

export const contactsBySearch = /* GraphQL */ `
    query ContactsBySearch($search: String!, $limit: Int!, $nextToken: String) {
        contactsBySearch(
            search: $search
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstName
                lastName
                title
                email
                workPhone
                homePhone
                company {
                    id
                    name
                }
                project {
                    id
                    name
                }
            }
            nextToken
        }
    }
`;

export const contactsBySalesOfficerEmail = /* GraphQL */ `
    query ContactsBySalesOfficerEmail(
        $email: String!
        $limit: Int!
        $nextToken: String
    ) {
        contactsBySalesOfficerEmail(
            email: $email
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstName
                lastName
                title
                email
                workPhone
                homePhone
                company {
                    id
                    name
                }
                project {
                    id
                    name
                }
            }
            nextToken
        }
    }
`;

/** Project filters */

export const projectsBySalesOfficerEmail = /* GraphQL */ `
    query ProjectsBySalesOfficerEmail(
        $email: String!
        $limit: Int!
        $nextToken: String
    ) {
        projectsBySalesOfficerEmail(
            email: $email
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                status
                index
                company {
                    id
                    name
                    logo
                }
                createdAt
                updatedAt
                stage
                salesOfficerEmail
            }
            nextToken
        }
    }
`;

/** Project filters */
